.form-select-custom{

	form{

		.form-group,
		.input-group{

			select.form-control{
				min-height:47px;
				border-color:#DDDDDD;
				font-size:14px;
				font-family: 'montserratblack';
				-webkit-appearance:none;
				background-image:url('../images/select-arrow.png');
				background-repeat:no-repeat;
				background-position:calc(100% - 12px) center;
				@include place(#000);
				@include sombra(#DDDDDD);
				padding-left:14px;
			}

		}

		.form-group.label-custom,
		.input-group.label-custom{
			align-items:center;

			label{
				margin-bottom:0;
				color:#00238C;
				margin-bottom:0;
				line-height:1;
				font-family: 'montserratblack';
				margin-right:8px;
				text-align:right;

				@include media-breakpoint-down(md){
					width:100%;
					text-align:center;
					margin-right:0;
					margin-bottom:12px;
				}

			}

		}

	}

}

.form-newsletter{

	form{
		max-width:418px;
		
		@include media-breakpoint-down(md){
			margin-right:auto;
			margin-left:auto;
		}

		.form-newsletter__title{
			font-size:15px;
			font-family: 'montserratblack';
			color:#FFF;
			margin-bottom:12px;

			span{
				color:$theme2;
			}

		}

		.form-group,
		.input-group{

			.form-control{
				min-height:48px;
				font-size:13px;
				font-family: 'montserratbold';
				@include place(#BBBBBB);
				background-color:#FFF;
				border-color:#FFF;
				border-radius:30px 0 0 30px;

			}

			.input-group-prepend{

				.btn{
					font-family: 'montserratbold';
					border-radius:0 30px 30px 0;

					@include media-breakpoint-up(lg){
						font-size:12px;
					}

				}

			}

		}

	}

}

.form-internas{

	form{

		.campo-obrigatorio{
			color:#E51825;
			font-size:12px;

			&.pro-lado-80{
				
				@include media-breakpoint-up(lg){
					margin-left:-80px;
				}

			}

		}

		.form-group,
		.input-group{
			margin-bottom:25px;

			label{
				font-size:14px;
				color:#656565;
			}

			.form-control{
				min-height:42px;
				background-color:#EEEEEE;
				border-color:#EEEEEE;
				font-size:13px;
				@include sombra(#EEEEEE);
				@include place(#333);
				resize:none;
				border-radius:0;
			}

			.select-custom{
				-webkit-appearance:none;
				background-image:url('../images/select-arrow.png');
				background-repeat:no-repeat;
				background-position:calc(100% - 12px) center;
			}

		}

	}

}

.form-internas-white{

	form{

		.input-group,
		.form-group{

			.form-control{
				min-height:40px;
				border-color:#D2D2D2;
				border-radius:2px;
				@include sombra(#D2D2D2);
			}

		}

	}

}

.form-internas-diferente{

	form{

		.form-group,
		.input-group{

			label{
				font-family: 'montserratbold';
			}

			.form-control{
				min-height:56px;
				font-size:14px;
				font-family: 'montserratbold';
				@include place(#555);
			}

		}

	}

}

.form-pesquisas-topo{

	@include media-breakpoint-up(lg){
		display:flex;
		flex-wrap:wrap;

	}

	form{
		flex-grow:1;

		.input-group{

			@include media-breakpoint-down(md){
				padding:30px 15px 0 15px;
			}

			label{
				font-size:12px;

				@include media-breakpoint-down(md){
					width:100% !important;
					display:block !important;
				}

				@include media-breakpoint-up(lg){
					@include sr-only();
				}

			}

			.form-control{
				background-color:#EEEEEE;
				border-color:#EEEEEE;
				font-size:12px;
				@include place(#777);
				@include sombra(#EEEEEE);
				min-height:41px;
				padding-left:18px;
				font-family: 'montserratbold';

				@include placeholdIt{
					color:transparent;
				}

			}

			.btn{
				padding:0;
				min-width:54px;
				display:flex;
				flex-wrap:wrap;
				align-items:center;
				justify-content:center;
			}

		}

	}

}