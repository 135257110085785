.rodape{

	@include media-breakpoint-down(md){
		text-align:center;
	}

	.rodape-principal,
	.rodape-copyright{
		position:relative;
		color:#FFF;

		@include beforeAfter{
			content:"";
			width:100vw;
			height:100%;
			background-color:inherit;
			position:absolute;
			top:0;
		}

		&:before{
			left:100%;
		}

		&:after{
			right:100%;
		}

	}

	.rodape-principal{
		background-color:$theme;
		padding-top:26px;
		padding-bottom:22px;

		.title-rodape,
		.title-telefone{
			color:$theme2;
			line-height:1;
		}

		.title-rodape{
			font-size:15px;
			font-family: 'montserratblack';
			margin-bottom:14px;
		}

		.title-telefone{
			font-size:50px;
			font-family: 'baloobhai-regular';
			display:block;

			@include media-breakpoint-down(md){
				font-size:35px;
			}

		}
		.rodape__somente{
			margin-bottom:26px;
			color: $theme2;
			font-family: 'baloobhai-regular';
			font-size: 16px;
			line-height: 16px;
		}

		.media-custom{
			margin-bottom:20px;

			.media-custom__img{
				max-width:40px;
			}

			.media-custom__content{
				max-width:200px;
				
				@include media-breakpoint-up(xl){
					max-width:284px;
				}

			}

		}

		.row-vantagens{
			border-bottom:1px solid #115694;
			margin-bottom:16px;
		}

		.row-dados{

			& > *{
				margin-bottom:32px;
			}

		}

	}

	.rodape-copyright{
		background-color:$theme2;
		padding-top:26px;
		padding-bottom:6px;
		font-size:13px;
		font-family: 'montserratthin';

		.rodape-copyright__content{
			position:relative;

			.irtopo{
				border:none;
				position:absolute;
				top:-62px;
				left:50%;
				transform:translateX(-50%);
				font-size:60px;
				border-radius:100%;
				background-color:$theme2;
				background-repeat:no-repeat;
				background-position:center calc(0% + 20px);
				z-index:300;
				width:1em;
				height:1em;
				display:flex;
				align-items:flex-start;
				justify-content:center;
				color:#FFF;

				@include hover-focus{
					color:darken(#FFF,10%);
				}

			}

		}

		span{
			font-family: 'montserratblack';
		}

		img{
			max-width:44px;
		}

	}

	.menu-rodape{
		list-style-type:none;
		margin-bottom:0;
		padding-left:0;
		font-family: 'montserratbold';
		margin-bottom:55px;
		font-size:13px;

		li{
			margin-bottom:22px;

			&:last-child{
				margin-bottom:0;
			}

			&.active > a,
			&:hover  > a{
				color:$theme2;
			}

		}

	}

}