.paginacao{
	display: flex;
	flex-wrap: wrap;

	&.paginacao__start{
		justify-content: flex-start;
	}

	&.paginacao__center{
		justify-content: center;
	}

	&.paginacao__end{
		justify-content: flex-end;
	}

	&.paginacao__between{
		justify-content: space-between;
	}

	.page__item {
		display: inline-block;
		padding: 5px 10px;
		min-width:33px;
		border: 1px solid #DDD;
		text-align:center;
		color: inherit;

		.fas{

			&.fa-chevron-left{
				@extend .fa-angle-double-left;
			}

			&.fa-chevron-right{
				@extend .fa-angle-double-right;
			}

		}

	}

	a.page__item:hover{
		text-decoration: none;
		color: #FFF;
		background: darken($theme2,2%);
	}

	span.page__item--active{
		background: $theme2;
		color: #FFF;
	}
}
