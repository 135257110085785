.card-produtos{
	max-width:256px;
	text-align:center;
	margin-bottom:42px;

	@include media-breakpoint-up(sm){
		display:flex !important;
		margin-bottom:0;
		padding-bottom:42px;

		& > a{
			display:flex;
			flex-wrap:wrap;

			& > *{
				width:100%;
			}

		}

	}

	.card-produtos__box{
		border:3px solid #CCCCCC;
		transition:border-color 0.6s linear;
		border-bottom:none;
		padding:8px 14px;
		height:100%;

		@include media-breakpoint-up(sm){
			display:flex;
			flex-wrap:wrap;

			& > *{
				width:100%;
			}

		}

	}

	.card-produtos__figure{
		height:235px;
		width:235px;
		margin-left:auto;
		background-color:#FFFFFF;
		margin-right:auto;
		display:flex;
		flex-wrap:wrap;
		align-items:center;
		justify-content:center;
		margin-bottom:12px;
		position:relative;

		.card-produtos__circle{
			font-size:17px;
			min-width:(60 / 17) * 1em;
			height:(60 / 17) * 1em;
			line-height:(60 / 17) * 1em;
			border-radius:100%;
			background-color:$theme;
			color:#FFF;
			position:absolute;
			top:7px;
			left:0;
			font-family: 'montserratbold';
		}

	}

	.card-produtos__content{

		@include media-breakpoint-up(sm){
			display:flex;
			flex-wrap:wrap;

			& > *{
				width:100%;
			}

		}

		.card-produtos__title,
		.card-produtos__preco{
			font-family: 'montserratbold';
		}

		.card-produtos__title,
		.card-produtos__desc{
			margin-bottom:2px;
		}

		.card-produtos__title{
			font-size:14px;
		}

		.card-produtos__desc{
			font-size:12px;
		}

		.card-produtos__preco{
			font-size:17px;
			color:$theme;
			margin-bottom:4px;
		}

	}

	.card-produtos__foter{

		@include media-breakpoint-up(sm){
			position:relative;

			& > *{
				width:100%;
			}

		}

	}

	&:hover{

		.card-produtos__box{
			border-color:$theme2;
		}

	}

}