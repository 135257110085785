.card-categorias{
	text-align:center;
	font-size:14px;
	line-height:1.2;
	margin-bottom:10px;
	
	.card-categorias__figure{
		margin-bottom:6px;
	}

	.card-categorias__desc{
		transition:color 0.6s linear;
		font-family: 'montserratbold';
	}

	&:hover{
		color:$theme2;
	}

}