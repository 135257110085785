.media-custom{
	display:inline-flex;
	align-items:center;
	flex-wrap:wrap;

	& + .media-custom{
		margin-left:32px;
	}

	.media-custom__img{

		@include media-breakpoint-up(md){
			margin-right:8px;
		}

	}

	.media-custom__title{
		font-size:17px;
		font-family: 'montserratblack';
		line-height:1;
		margin-bottom:2px;
	}

	.media-custom__desc{
		font-size:12px;
		font-family: 'montserratregular';
		letter-spacing:3px;
	}

}