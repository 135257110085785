@import "_home.scss";

.title-radios{
	font-size:24px;
	margin-bottom:12px;
}

.radios{
	margin-bottom:20px;

	.radio-custom{
		margin-left:0;
	}

}

.internas{
	padding-bottom:36px !important;

	.internas__menu-filtro{
		background-color:darken($theme,10%);
		color:#FFF;
		list-style-type:none;
		padding-left:0;
		border-radius:0;
		float:none;
		margin:0;
		min-width:174px;

		li{

			a{
				display:block;
				padding:8px 10px;
			}

			&.active > a,
			&:hover  > a{
				background-color:darken($theme,20%);
			}

		}

	}

	.hr-divider{
		border-top:8px solid #DDDDDD;
	}
	
	.internas-breadcrumb{
		background-color:$theme2;
		color:#FFF;
		list-style-type:none;
		margin-bottom:46px;
		position:relative;
		padding-left:0;
		text-align:right;
		font-size:11px;
		font-family: 'montserratbold';
		padding-top:7px;
		padding-bottom:7px;

		@include beforeAfter{
			content:"";
			width:100vw;
			height:100%;
			position:absolute;
			top:0;
			background-color:inherit;
		}

		&:before{
			left:100%;
		}

		&:after{
			right:100%;
		}

	}

	.internas__title{
		font-size:23px;
		line-height:1;
		color:$theme;
		font-family: 'montserratblack';
		padding-bottom:30px;
		position:relative;
		margin-bottom:45px;

		&:after{
			content:"";
			display:block;
			background-color:$theme;
			width:100%;
			height:11px;
			position:absolute;
			bottom:0;
			left:0;
		}

	}

	&.internas-cadastro{

		.form-cadastro{

			&.pessoaFisica{

				.juridico{
					display:none !important;
				}

			}

			&.pessoaJuridica{

				.fisica{
					display:none !important;	
				}

			}

			fieldset{
				max-width:890px;
				margin-right:auto;
				margin-left:auto;
			}

		}

	}

	&.internas-login{

		.form-internas-white{
			max-width:320px;
		}

		form{
			// margin-left:auto;
			// margin-right:auto;
		}

		.row{

			& > *:first-child{

				&:before{
					content:"";
					width:1px;
					height:100%;
					background-color:#C7C7C7;
					position:absolute;
					top:0;
					left:100%;
				}

			}

		}

	}

	&.internas-categorias{

		.card-produtos{
			margin-bottom:42px;
		}

		.col-lg-9{

			.row{
				align-items:stretch !important;

				& > *{

					@include media-breakpoint-up(sm){
						display:flex;
					}

				}

			}

		}

	}

	.max-content{
		margin-left:auto;
		margin-right:auto;

		&.max-878{
			max-width:878px;
		}

		&.max-922{
			max-width:922px;
		}

	}

	&.internas-meus-pedidos-detalhes{

		.bg-gray{
			background-color:#EDEDED;
			padding:24px 15px 14px 15px;

			.data{
				font-size:14px;
			}

			.calendario{
				font-size:14px;
			}

		}

		.pedido-realizado{
			flex-grow:1;
			font-size:11px;

			.tab-custom{
				background-color:#EDEDED;
				padding:8px 16px 8px 32px;
				position:relative;
				margin-bottom:10px;
				z-index:2;
				text-align:center;
				color:#1D1D1D;
				font-family: 'montserratbold';

				@include beforeAfter{
					content:'';
					display:block;
					position:absolute;
					border:16px solid transparent;
					z-index:-2;
					height:100%;
				}

				&:before{
					left:100%;
					top:0;
				}

				&:after{
					border-left-color:#FFF;
					left:0;
					top:0;
				}

				&.active{
					background-color:darken($theme2,0%);
					color:#fff;

					&:before{
						left:calc(100% + 1px);
						border-left-color:darken($theme2,0%) !important;
					}

				}

			}

			& + .pedido-realizado{

				.tab-custom{

					&:before{
						border-left-color:#EDEDED;
					}

				}

			}

			&:first-child{

				.tab-custom{

					&:after{
						border-left-color:transparent;
					}

				}

			}

			&:last-child{

				.tab-custom{

					&:before{
						border-left-color:transparent;
					}

				}

			}

		}

	}

	&.internas-produtos-detalhes{

		.card.card-add-carrinho{
			padding:18px 12px;
		}

		lazy-image{
			display:inline-block;
		}

		.internas-content{
			padding-top:35px;
		}

		.fotos-detail-prod{

			.h5{
				color:#000000;
				font-size:12px;
				margin-bottom:22px;
			}

		}

		.title-valor{
			font-size:35px;
			line-height:1;
			margin-bottom:0;

			.text-small{
				font-size:15px;
			}

		}

		.texto-parcela{
			font-size:15px;
		}

		.rating{
			color:#C6C6C6;

			.fa-star{

				&.full{
					color:#EEC829;
				}

				&.half{
					position:relative;

					&:after{
						content:'\f089';
						display:inline-block;
						width:100%;
						height:100%;
						position:absolute;
						top:0;
						left:0;
						@extend .fas;
						color:#EEC829;
					}

				}

			}

			.fa{
				@include margin(0,2);

			}

			&.rating-diferente{

				.fas{
					color:#CFCFCF;

					&.full{
						color:#E9C620;
					}

					&.half{

						&:after{
							content:'\f089';
							display:inline-block;
							width:100%;
							height:100%;
							position:absolute;
							top:0;
							left:0;
							color:#E9C620;
						}

					}

				}

			}

		}

		.hr-divider{
			border-color:#EDEDED;
			border-width:4px;
		}

		.divider-hr{
			border-top:5px solid #D6D6D6;
		}

		.row-imagens{

			& > *{
				@include margin(0,15);
				transition:box-shadow 0.6s linear;

				img{
					transition:border 0.6s linear;
					border:2px solid transparent;
				}

				a{
					z-index:2;
					position:relative;
					display:block;

					&.zoomGalleryActive,
					&:hover,
					&:focus{
						
						img{
							border-color:$theme;
						}

					}

				}

				@include hover-focus(){
					border-color:$theme;
				}

				&:hover{
					box-shadow:none;

					a:before{
						opacity:1;
						z-index:30;
					}

				}

			}

		}

		.media-legal{
			font-size: 40px;
			margin-bottom:15px;
			text-align:center;
			align-items:center;
			padding-bottom:0;
			border-bottom:1px solid #E3E3E3;

			.nota{
				color: #222;
			}

			.rating,
			.nota{
				line-height:1;
			}

		}

		.depoimento{
			margin-bottom:12px;
			padding-bottom:20px;
			border-bottom:1px solid #E3E3E3;

			.autor{
				color:#666;
			}

			&.depoimento-peq{
				border:none;
				padding-bottom:0;
				margin-bottom:0;
			}

			&.depoimento-grande{
				font-size:30px;

				.rating{
					margin-bottom:20px;
				}

			}

			&:last-child{
				border:none;
			}

		}

		.details-prod-text{
			font-size: 16px;
			color: #383838;

			.card-body-cep{
				color:#333333;
				font-size:12px;

				.form-control{
					@include place(#8F8F8A);
					font-size:13px;
				}

				.form-control,
				.input-group-addon .btn{
					border-color:#ccc;
				}

				.fa-truck{
					margin-right:5px;
					font-size:15px;
				}

				.cep-text{
					font-size:14px;
					color:#333333;
				}

			}

			.avaliacoes{
				font-size:12px;
				align-self:center;
				color:$theme;
				font-family: 'montserratbold';
			}

			.card{

				.btn-theme.btn-expanded{
					font-size:18px;

					.fa{
						font-size:25px;
						margin-right:3px;
					}

				}

			}

			.nome,
			.cod{
				color:#000;
			}

			.nome{
				font-size: 18px;
				margin-bottom: 15px;
				color:#000;
			}

			.cod{
				margin-bottom: 16px;
				font-size:12px;
			}

			.bg-grey{
				background: #EDEDED;
			}

			.card{
				border-radius:0;
				border:none;

				.btn-redondo{

					.fa{
						margin-right:10px;
						font-size:26px;
					}

				}

				.card-body{

					& > .row{
						align-items:center;
						margin-bottom:10px;
					}

				}

				.form-control{
					border-color:#cecece;
					min-height:44px;
					border-right:none;
					font-size:12px;
					border-radius:0;
					@include sombra(#ccc);
					@include place(#8E8E89);
					font-family: 'montserratbold';
				}

				.input-group-addon{

					.btn{
						border-color:#cecece !important;
						border-left:none;
						font-size:13px;
						color:#1F1F1F;
					}

				}

				.btn-frete{
					color:#FFF;
					width:28px;
					height:28px;
					line-height:28px;
					text-align:center;
					font-size:10px;
					padding:0;
					border-radius:100%;
				}

			}

			.form-control{
				border-color: #FFF;
				font-size:12px;
				color:#8E8E89;

				@include placeholdIt{
					color:#8E8E89;
				}

			}

			.input-group{
				
				.input-group-addon{
					display:flex;

					.btn{
						font-size:13px;
						border-radius: 0;
						@include button-variant(#FFF, #FFF);
						color:lighten(#1F1F1F,10%);

						&:hover{
							border-color: #222;
							color:#1F1F1F;
						}
					}

				}

			}

			.btn{
				white-space:normal;

				&.btn-question{
					padding:0;
					width:24px;
					height:24px;
					display:flex;
					align-items:center;
					border-radius:100%;
					justify-content:center;
				}


				.fas{
					vertical-align:bottom;
				}

			}

			.card-preco{
				margin-bottom: 15px;
				display: flex;
				flex-wrap: wrap;
				text-align: center;
				font-size:14px;
				color:$theme;

				.boleto{
					font-size: 18px;
				}

				.special{
					color: $theme;
					font-size:13px;
					
					span{
						font-size: 20px;
					}

				}

				.cond{
					font-size:12px;
				}

				@include media-breakpoint-up(sm){
					flex-direction: row;
					align-items: center;

					.boleto{
						border-left:2px solid #d1d0d0;
					}
				}

				& > *{
					min-width: 50%;
					padding: 15px;

					@include media-breakpoint-up(lg){
						max-width:50%;
					}

				}
			}

			.card-body-adicionar{

				.btn{
					padding:6px 12px;
					font-size:18px;

				}

			}

			.compartilhar{

				.h5{
					font-size:12px;
					color:#1F1F1F;
					margin-bottom:0;
				}

				a{
					display: inline-block;
				}
			}
		}

		.avaliar{

			button,
			h2,
			.radio-custom{
			}

			h2 {
				color: #666;
				font-size: 20px;
				margin-bottom: 10px;
			}

			.subtitle{
				font-size:15px;
				color:#666;
				margin-bottom:15px;
			}

			.form-group{

				.subtitle{
					margin-bottom:10px;
				}

				.form-control{
					resize:none;
					background-color:#eeeeee;
					@include sombra(#EEE);
					border:none;
				}

			}

			.radio-custom{
				font-size:15px;
				color:#666;

			}

		}

		.media-legal .badge,
		.depoimento .badge{
			display:inline-block;
		}

		.modal-saber-cep{


			.modal-content{
				padding:20px;
			}

		}

		.form-cadastro-modal{
			padding:20px;

			.form-group,
			.input-group{

				.form-control{
					background-color:#ccc;
					@include sombra($theme);
					@include place(#111);
				}

			}

		}
		
	}

}