.menu-principal{
	list-style-type:none;
	padding-left:0;
	font-size:12px;
	font-family: 'montserratbold';
	margin-bottom:20px;

	@include media-breakpoint-up(lg){
		margin-bottom:8px;
		display:flex;
		flex-wrap:wrap;
		align-items:center;
		padding-left:26px;
	}

	li{
		flex-grow:1;

		a{
			flex-grow:1;
			display:block;

			@include media-breakpoint-down(md){
				padding:12px 20px;
				border-top:1px solid transparent;
				border-bottom:1px solid transparent;
			}

		}

		&.active,
		&:hover {

			a{
				
				@include media-breakpoint-up(lg){
					color:$theme2;
				}

				@include media-breakpoint-down(md){
					background-color:$theme2;
					color:#FFF;
					border-color:darken($theme2,10%);
				}

			}

		}

	}

}