.topo{

	.bar-topo{
		padding-top:11px;
		padding-bottom:4px;
		background-color:$theme;
		color:#FFF;
		font-size:13px;
		font-family: 'montserratthin';

		@include media-breakpoint-down(md){
			text-align:center;
		}
		
		b{
			font-family: 'montserratbold';
		}

		.row > * {
			margin-bottom:3px;
		}

	}

	.nav-content{

		.dropdown.dropdown-user{

			@include media-breakpoint-down(md){
				display:none;
			}

		}

		@include media-breakpoint-down(md){
			position:fixed;
			top:0;
			background-color:#FFF;
			left:-240px;
			width:240px;
			height:100%;
			overflow:auto;
			z-index:1000;
			transition:left 0.6s linear;
			border-right:1px solid $theme;

			.logo-desktop{
				padding:30px;
				text-align:center;
				margin-bottom:0 !important;
			}

		}
		
		@include media-breakpoint-up(lg){
			@include make-container();
			@include make-container-max-widths();
			display:flex;
			flex-wrap:wrap;
		}

		& > *{
			width:100%;
		}

		.bar-principal{

			@include media-breakpoint-up(lg){
				padding-top:17px;
				display:flex;
				flex-wrap:wrap;
				align-items:center;
				justify-content:space-between;
			}

			.secundaria-user{
				position:relative;

				@include media-breakpoint-up(lg){
					display:flex;
					flex-wrap:wrap;
					margin-bottom:0;
					padding-left:6px;
				}

				&:before{
					
					@include media-breakpoint-up(xl){
						content:"";
						display:block;
						position:absolute;
						top:0;
						left:-22px;
						width:1px;
						height:41px;
						background-color:#DDDDDD;
					}

				}

				& > *{
					
					@include media-breakpoint-up(lg){
						@include margin(0,9);
					}

				}

				.secundaria-user__carrinho{
					position:relative;

					@include media-breakpoint-down(md){
						display:none;
					}

					@include media-breakpoint-up(lg){
						display:inline-block;
					}

					.number{
						font-size:14px;
						font-family: 'montserratbold';
						background-color:$theme2;
						color:#FFF;
						border-radius:100%;
						padding:2px;
						min-width:(24 / 14) * 1em;
						min-height:(24 / 14) * 1em;
						position:absolute;
						top:-9px;
						left:26px;
						text-align:center;
					}

					.text{
						vertical-align:bottom;
						position:relative;
						top:6px;
						left:2px;
						font-size:13px;
						font-family: 'montserratbold';
					}

				}

			}

			& > *{
				margin-bottom:30px;
			}

			.bar-principal__principal-user{
				flex-grow:1;

				@include media-breakpoint-up(lg){
					max-width:656px;
					margin-left:33px;
					margin-right:auto;
					padding-top:22px;
				}

			}

		}

		.bar-secundaria{
			background-color:$theme;
			color:#FFF;
			padding-top:20px;
			padding-bottom:4px;
			position:relative;

			.col-lg-auto.dropdown{

				&:hover{

					.dropdown-menu{
						display:block;
					}

				}

			}

			.dropdown-menu{
				background-color:darken($theme,10%);
				color:#FFF;
				padding:10px 15px;

				@include media-breakpoint-up(lg){
					min-width:270px;
				}

				@include media-breakpoint-down(md){
					display:block;
					position:static;
					float:none;
					width:100%;
					margin-bottom:15px;
					text-align:center;
				}

				&:before{
					content:'';
					display:block;
					position:absolute;
					bottom:100%;
					left:0;
					width:100%;
					border-top:10px solid transparent;
				}

				&:hover{
					display:block;
				}

				li{

					a{
						display:block;
						word-break:normal;

						@include media-breakpoint-down(md){
							margin-bottom:8px;
						}

					}

					&.active,
					&:hover{

						a{
							color:$theme2;
						}

					}

				}

			}

			.row{

				@include media-breakpoint-down(md){
					margin-left:0;
					margin-right:0;
				}

			}

			@include media-breakpoint-up(lg){
				padding-left:26px;
				padding-right:26px;

				@include beforeAfter{
					content:"";
					display:block;
					position:absolute;
					top:0;
					background-color:inherit;
					width:100vw;
					height:100%;
				}

				&:before{
					left:100%;
				}

				&:after{
					right:100%;
				}

			}

			.col-lg-auto{
				min-width:168px;

				@include media-breakpoint-down(md){
					padding-left:0;
					padding-right:0;
				}

				& + .col-lg-auto{

					@include media-breakpoint-up(lg){
						
						&:before{
							content:"";
							width:1px;
							height:54px;
							position:absolute;
							top:calc(50% - 6px);
							left:-2px;
							transform:translateY(-50%);
							background-color:#4B78A1;
						}

					}

				}

			}

		}

	}

	&.fx{

		.mbl-controls{
			position:fixed;
			top:0;
			left:0;
			animation:fadeInDown 0.6s linear;
			width:100%;
			z-index:400;
			box-shadow:0 0 8px rgba(#000,0.5);
		}

	}

}
.somente{
	display: inline-block;
	margin-left: 5px;
}
.icone__whats{
	font-size: 16px;
	line-height: 16px;
	margin-right: 5px;
	margin-bottom: 2px;
}