@import "_fontfaces";

body{
	font-size:14px;
	color:#000;
	font-family: 'montserratregular';
}

.bg-menu{
	display:none;
	position:fixed;
	top:0;
	left:0;
	width:100%;
	height:100%;
	background-color:rgba(#000,0.5);
	z-index:800;
}

#app{
	position:relative;
	overflow:hidden;
}

.font--fnt-11{
    font-size:11px;
}

.font--fnt-15{
	font-size:15px;
}

.font--fnt-16{
    font-size:16px;
}

.font--fnt-17{
	font-size:17px;
}

.font--fnt-18{
	font-size:18px;
}

.font--fnt-23{
    font-size:23px;
}

.font--fnt-25{
    font-size:25px;
}

.font--fnt-27{
    font-size:27px;
}

.rodape a{
	word-break:break-all;
}

.logo{
	margin-bottom:0;
}

.internas img{
	@include img-fluid();
}

a,
.btn{
	transition:all 0.6s linear;
}

.text--theme{
    color:$theme !important;
}

.text--black{
    color:#000 !important;
}

.text--545454{
    color:#545454 !important;
}

a{
	color:inherit;
	text-decoration:none;

	@include hover-focus{
		color:inherit;
		text-decoration:none;
	}

}

*[data-animate]{
	opacity:0;
}

.animated{
	animation-direction:1.4s;
	animation-fill-mode: both;
}

.animated[data-animate]{
	opacity:1;
}

.atraso-1{
	animation-delay:0;
}

.atraso-2{
	animation-delay:0.2s;
}

.atraso-3{
	animation-delay:0.4s;
}

.atraso-4{
	animation-delay:0.6s;
}

lazy-image{
	background-color:transparent;
}

.slick-slider{

	img{
		display:inline-block !important;
	}

}

.carousel{
	text-align:center;

	.carousel-item{
		position: relative;
		z-index:200;
	}

}

:focus{
	outline:none !important;
}

.slick-slider{

	.slick-list{
		@include media-breakpoint-up(sm){
			display:flex;
		}
	}

	.slick-track{
		text-align:center;
		
		@include media-breakpoint-up(sm){
			display:flex;
			padding-bottom:42px;

			.slick-slide{
				display:flex;

				& > *{
					display:flex;
				}

			}

		}

	}

	img{
		@include img-fluid();
	}

}

// FONTS
.font--italic{
	font-style:italic;
}

.link{

	& + .link{

		&:before{
			content:"|";
			margin:0 4px;
		}

	}

}


// concerta erro meu (João) de sprites em outros projetos
.icon{

	@each $icon,$value in $icons {
		&.#{$icon} {
			width: nth($value,3);
			height: nth($value,4);
			background-position: #{nth($value,1)} #{nth($value,2)};
		}
	}
}

.line--height-1{
	line-height:1;
}

// FONTS USADAS
.font--baloo-reg{
	font-family: 'baloobhai-regular';
}

.font--mont-reg{
	font-family: 'montserratregular';
}

.font--mont-blk{
	font-family: 'montserratblack';
}

.font--mont-bld{
	font-family: 'montserratbold';
}

.font--pops-reg{
	font-family: 'poppinsregular';
}

.font--mont-lgt{
	font-family: 'montserratlight';
}

.font--mont-thin{
	font-family: 'montserratthin';
}

[data-desligador]{
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color:rgba(#000,0.5);
    z-index:600;
}

.dropdown-toggle{

	&:after{
		display:none !important;
	}

}

body{

    &.menu-active{

        .topo{

            .nav-content{
                left:0;
            }

        }

        .btn.btn--responsivo{

            .bars{
                transform:rotate(45deg);

                @include beforeAfter{
                    transform:rotate(-90deg);
                }

                &:before{
                    top:0;
                }

                &:after{
                    bottom:0;
                }

            }

        }

    }

}