.banner{

	.carousel-item{
		margin-bottom:0;
	}

	.carousel-controls{
		width:100%;
		height:100%;
		display:flex;
		flex-wrap:wrap;
		align-items:center;
		justify-content:center;
		position:absolute;
		top:0;
		left:0;

		.clearfix{
			width:100%;
			@include make-container();
			@include make-container-max-widths();

			@include media-breakpoint-up(xl){
				max-width:1500px;
			}

		}

	}

}

.arrow-control{
	font-size:18px;
	display:flex;
	align-items:center;
	justify-content:center;
	flex-wrap:wrap;
	@include button-variant($theme2,$theme2);
	color:#FFF;
	border-radius:100%;
	position:relative;
	z-index:200;
	width:(30 / 18) * 1em;
	height:(30 / 18) * 1em;
	line-height:(30 / 18) * 1em;

	@include media-breakpoint-up(md){

		width:(52 / 18) * 1em;
		height:(52 / 18) * 1em;
		line-height:(52 / 18) * 1em;

	}

	&.arrow-control-left{
		transform:rotate(180deg);
	}

}

.vantagens{
	background-color:#F4F4F4;
	padding-top:12px;
	padding-bottom:3px;

	.row{

		& > * figure{

			@include media-breakpoint-up(lg){
				margin-bottom:6px;
			}

		}

	}

}

.novidades{
	padding-top:32px;
	padding-bottom:2px;
}

.destaques{
	padding-bottom:22px;
}

.baterias{
	padding-top:22px;
	margin-bottom:22px;

	.baterias__desc{
		min-height:110px;
	}

}

.ofertas,
.produtos,
.lancamentos{
	padding-top:22px;
	padding-bottom:13px;
}

.ofertas,
.produtos,
.lancamentos,
.novidades,
.destaques,
.baterias{
	
	@include media-breakpoint-down(md){
		text-align:center;
	}

}

main.wrapper{

	& > *{

		&:last-child{
			padding-bottom:54px;
		}

	}

}

.controls-diferente{
	margin-bottom:12px;

	.clearfix{
		text-align:center;

		& > *{
			@include margin(0,20);
		}

		.clearfix__arrow-right{
			transform:rotate(180deg);
		}

	}

}