.redes-sociais{

	& > *{
		font-size:22px;
		width:(36 / 22) * 1em;
		height:(36 / 22) * 1em;
		line-height:(36 / 22) * 1em;
		border-radius:100%;
		background-color:#FFF;
		color:$theme2;
		text-align:center;
		@include margin(0,6);
	}

	&.redes-sociais-internas-diferente{

		& > *{
			font-size:20px;
			background-color:$theme;
			color:#FFF;
			border-radius:4px;
			@include margin(0,3);

			&.fa-facebook{
				background-color:#425D8A;

				@include hover-focus{
					background-color:darken(#425D8A,10%);
				}

			}

			&.fa-twitter{
				background-color:#64ABEB;

				@include hover-focus{
					background-color:darken(#64ABEB,10%);
				}

			}

			&.fa-youtube{
				background-color:#C04D3B;

				@include hover-focus{
					background-color:darken(#C04D3B,10%);
				}

			}

			&.fa-spotify{
				background-color:#1DB954;

				@include hover-focus{
					background-color:darken(#1DB954,10%);
				}

			}

		}

	}

}