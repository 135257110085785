.produto-table{
	margin-bottom:45px;

	*[data-before]{
		
		@include media-breakpoint-down(md){
			padding-bottom:15px;
			
			&:before{
				content:attr(data-before);
				display:block;
				padding:10px 0;
				color:darken($theme,10%);
				font-size:20px;
			}

		}

	}

	.produto-header{
		color:#FFF;
		font-size:13px;
		background-color:$theme;
		padding-left:14px;
		padding-right:14px;
		display:flex;
		justify-content:space-between;
		align-items:center;
		flex-wrap:wrap;
		font-family: 'montserratbold';

		@include media-breakpoint-down(md){
			display:none;
		}

		.box-text{
			display:inline-block;
			padding:8px 16px;
			flex-grow:1;
		}

	}

	.produto-body{
		padding:25px 32px;
		border-top:none;
		border:1px solid #ECECEC;
		background-color:#EDEDED;

		.produto-item{
			font-size:14px;

			@include media-breakpoint-down(md){
				text-align:center;
				margin-bottom:50px;
			}

			.text-grande{
				font-size:20px;
			}

			.valor-total{
				font-family: 'montserratbold';
			}

			& > *{
				margin-bottom:12px;
			}

			@include media-breakpoint-up(lg){
				display:flex;
				align-items:center;
				justify-content:space-between;
				flex-wrap:wrap;
			}

			.produto-item-head{

				@include media-breakpoint-up(lg){
					flex-grow:1;
					max-width:100px;
					margin-right:auto;
					padding:10px;
					display:flex;
					justify-content:space-between;
					align-items:center;
					flex-wrap:wrap;
				}

				.figure-produto{
					max-width:105px;
				}

				.figure-text{
					width:50%;
					flex-grow:1;
				}
				
				@include media-breakpoint-up(lg){
					max-width:320px;
					margin-right:auto;
				}


				.figure-text{
					padding:20px 0 20px 20px;

				}

				& > *{
					margin-bottom:12px;
				}


				@include media-breakpoint-down(md){
					text-align:center;

					.figure-produto{
						margin-left:auto;
						margin-right:auto;
					}

					.figure-text{
						width:100%;
					}

				}

			}

			.produto-item-qtd{
				max-width:100px;
				margin-right:auto;
				flex-grow:1;
				font-family: 'montserratbold';

				@include media-breakpoint-down(md){
					max-width:100%;
					margin-left:auto;
				}

			}

			.produto-item-footer{

				.valor-unit{
					flex-grow:1;

					@include media-breakpoint-up(lg){
						min-width:123px;
					}

				}

				@include media-breakpoint-up(lg){
					display:flex;
					flex-wrap:wrap;
					justify-content:space-between;
					align-items:center;
					max-width:300px;
					width:100%;
					margin-right:auto;

					& > *{
						width:auto;
					}

				}

			}

		}

	}

}