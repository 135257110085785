.dropdown-user{
	text-align:center;

	.dropdown-toggle{
		border:none;
		padding:0;
		box-shadow:none;
		position:relative;
	}

	.dropdown-menu{
		padding:0;
		margin:0;
		min-width:200px;
		font-size:13px;
		font-family: 'montserratbold';
		z-index:700;
		float:none;

		@include media-breakpoint-down(md){
			top:8px !important;
		}

		@include media-breakpoint-up(lg){
			top:calc(100% + 16px) !important;
			left:50% !important;
			position:absolute;
			transform:translateX(-50%) !important;
		}

		li{

			&.dropdown--header{
				background-color:#000;
				color:#FFF;
				padding:12px 12px;
				display:block;
				position:relative;

				&:before{
					content:"";
					position:absolute;
					left:50%;
					transform:translateX(-50%);
					bottom:100%;
					border:16px solid transparent;
					border-left-width:24px;
					border-right-width:24px;
					border-bottom-color:#000;
				}

			}

			&:not(.dropdown--header){
				
				& > a:not(.btn){
					background-color:#FFF;
					color:#000;
					display:block;
					padding:13px 15px;
					border-bottom:1px solid #CCCCCC;

					&:hover{
						background-color:darken(#FFF,10%);
					}

				}

			}

			&:last-child{
				border-bottom:none;
				text-align:center;
				padding:14px 0 10px 0;
			}

		}

	}

}