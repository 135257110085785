.dropdown-topo{
	color:#777777;
	background-color:#EEEEEE;
	font-size:12px;
	font-family: 'montserratbold';
	position:relative;
	line-height:1;
	display:flex;
	flex-wrap:wrap;
	align-items:center;
	min-height:41px;
	min-width:190px;

	@include media-breakpoint-down(md){
		display:none !important;
	}

	&:before{
		
		@include media-breakpoint-up(lg){
			content:"";
			display:block;
			width:1px;
			height:24px;
			background-color:#DADADA;
			position:absolute;
			top:50%;
			transform:translateY(-50%);
			right:0;
		}

	}

	.btn-dropdown-toggle{
		padding:0;
		border:none;
		font-size:12px;
		color:inherit;
		box-shadow:none;
		height:100%;
		padding:10px 15px;

		@include media-breakpoint-down(md){
			font-size:14px;
			text-align:center;
		}

	}

	.dropdown-menu{
		background-color:#EEEEEE;
		border-radius:0;
		width:100%;
		border:none;
		padding:0;
		min-height:80px;
		z-index:600;

		@include media-breakpoint-down(md){
			position:relative;
			display:block;
		}

		li{

			a{
				padding:12px 15px;
				display:block;
				color:#666;
				font-size:13px;
			}

			&.dropdown{

				@include media-breakpoint-down(md){
					background-color:darken(#EEE,10%);
				}

				& > a{

					&:before{
						content:"-";
						display:inline-block;
						margin-right:6px;
					}

				}

				&:hover{

					& > .dropdown-menu-dentro{
						display:block;
					}

				}

			}

		}

		.dropdown-menu-dentro{
			list-style-type:none;
			padding-left:0;
			margin-bottom:0;

			@include media-breakpoint-down(md){
				background-color:darken(#EEE,20%);
			}

			@include media-breakpoint-up(lg){
				display:none;
				position:absolute;
				top:0;
				left:100%;
				background-color:darken(#EEE,3%);
				width:100%;
			}

		}

	}

}