.header-section{
	font-size:36px;

	.header-section__title{
		font-size:1em;
		line-height:1;
		font-family: 'montserratblack';
		position:relative;

		&:before{
			content:"";
			display:block;
			width:35px;
			height:7px;
			background-color:$theme;
			margin-bottom:14px;

			@include media-breakpoint-down(md){
				margin-right:auto;
				margin-left:auto;
			}

		}

		&.title-before-mais{

			&:before{
				margin-bottom:30px;
			}

		}

	}

}

.row.row-header-section{

	& > *{
		margin-bottom:24px;
	}

}