.content-carrinho{

	.carrinho-continuar{

		.btn{
			padding:9px 25px;
			font-size:13px;
		}

	}

	.carrinho-item{
		color:#333;

		.descricao{
			font-size:14px;
		}

		.vlr-unit{
			font-size:13px;

			.content{
				color:#333;
			}

			.special{
				line-height:1;

				.final{
					font-size:20px;
				}

			}

		}

		&.carrinho-sem-desconto{

			.vlr-unit{

				.de,
				.special .por{
					display:none;
				}

			}

		}

	}


	
	@include media-breakpoint-up(lg){

		padding-left:28px;
		padding-right:35px;

		.carrinho-continuar{

			.btn{
				min-width:224px;

				.fa{
					margin-right:8px;
					font-size:24px;
					vertical-align:middle;
				}

			}

		}

	}

}

.carrinho{
	color:#323D54;

	.carrinho-header{
		color: #FFF;
		background: $theme;
		font-size: 13px;
		display: flex;
		font-family: 'montserratbold';

		.produto-area{
			padding: 7px 7px 7px 30px;

			& ~ * {
				text-align: center;
			}
		}
	}

	.vlr-unit,
	.vlr-ttl{
	}

	.carrinho-item{
		display: flex;
		border-bottom:2px solid #000;
		border-left:1px solid #000;
		border-right:1px solid #000;

		.produto-area{

			@include media-breakpoint-only(lg){
				border-bottom:4px solid #EDEDED;
				max-width:250px;
			}

		}

		@include media-breakpoint-down(lg){
			flex-wrap:wrap;
		}

		.qtde{
			padding-top:24px;

			& > .text-center{
				line-height:1;
			}

			.guardar-text{
				font-size:12px;
				color:#666;
			}

		}

		.foto-produto{
			max-width: 90px;
		}

		// &:hover{
		// 	background: lighten(#CCC,18%);
		// }

		// &:last-child{
		// 	border-bottom:2px solid #333;
		// }

		// & + .carrinho-item{
		// 	border-top: 1px solid #333;
		// }
		
		.produto-area{
			padding:22px;
			position: relative;
		}

		.vlr-ttl{
			font-size: 20px;
			text-align: center;
		}

		.vlr-unit{

			s,
			.special{
			}

		}

		.special{
			font-size:13px;

			.final{
				font-size:20px;
				color:$theme2;
				font-family: 'montserratbold';
			}

		}

		&.carrinho-sem-desconto{

			.de,
			.por{
				display:none;
			}

		}

	}

	.toggle-qtde{
		.btn{
			border-radius: 0;
		}

		.form-control{
			text-align: center;
			background-color:#EDEDED;
			border:none;
			@include place(#303030);
			@include sombra(#EDEDED);
			font-size:14px;
			min-height:40px;
			box-shadow:none;
		}
	}

	.excluir-item{
		position:absolute;
		border-radius:100%;
		z-index:2;
		left:5px;
		top:5px;
		background-color:rgba(darken(red,15%),0.6);
		color:#FFF;
		width:33px;
		height:33px;
		@include sombra(lighten(red,20%));

		@include hover-focus{
			@include button-variant(rgba(darken(red,20%),0.8),rgba(darken(red,20%),0.8));
			color:#FFF;
		}

	}

	.carrinho-footer{
		color:#656565;

		.form-control{
			@include place(#1F1F1F);
			border-color:#EDEDED;
			font-size:13px;
			background-color:#EDEDED;
			@include sombra(#EDEDED);
		}

		.nao-sei-cep{
			color:#1F1F1F;
			font-size:11px;
			text-align:right;
		}

		.carrinho-finalizar{

			.box-content{
				color:#1F1F1F;
			}

			.form-control{
				@include place(#333);
				padding-left:28px;
			}


		}

		.carrinho-entrega{

			.texto-calcular{
				font-size: 12px;
				color:#333;
			}

			.carrinho-calcular{
				border:1px solid #EDEDED;
				padding:20px 12px;
				font-size:13px;
				position:relative;

				& > p{
					color:#1f1f1f;
				}

				&:before{
					content:'';
					display:block;
					position:absolute;
					top:calc(0% + 20px);
					right:calc(100% - 3px);
					width:3px;
					height:29px;
					background-color:$theme2;
				}

				.destaque{
					color:#222;
				}

				.btn{
					min-width:127px;
					font-size:14px;
				}

				.carrinho-flex{
					
					.form-group{
						margin-bottom:0;

						.form-control{
							background-color:#EDEDED;
							@include sombra(#EDEDED);
						}

					}

				}

			}

			.carrinho-total{

				& > *{	
					font-size:13px;

					.flex-total{
						display:flex;
						margin-bottom:4px;
						flex-wrap:wrap;
						justify-content:space-between;
						align-items:center;
					}

					.span-total{
						color:#263351;
					}

					.valor-total{
						font-size:20px;
						color:$theme2;
						font-family: 'montserratbold';
					}

				}

				.parte-1{
					background-color:$theme;
					color:#FFF;
					margin-bottom:10px;
					padding:20px 16px 11px 25px;
				}

				.parte-2{
					background-color:#ededed;
					padding:25px 18px 18px 20px;

					.form-control{
						color:#999999;
						font-size:13px;
						border-color:#dadada;

						@include placeholdIt{
							color:#999999;
						}

					}

				}

			}

		}

	}

	@include media-breakpoint-down(xs){
		.carrinho-header{
			.produto-area ~ *{
				display: none;
			}
		}

		.carrinho-item{
			flex-wrap: wrap;
			text-align: center;

			.foto-produto{
				margin-right:auto;
				margin-left:auto;
			}

			.produto-area{
				width: 100%;

				& ~ *{
					padding: 15px;
					width: 100%;
				}
			}
		}

		.carrinho-footer{

			.box-btn{

				& > .btn{
					margin-bottom:10px;
					display:block;
					margin-right:auto !important;
					margin-left:auto !important;
				}

			}

		}

	}

	@include media-breakpoint-between(sm, md){
		.carrinho-header{
			.produto-area ~ *{
				display: none;
			}
		}

		.carrinho-item {
			flex-wrap: wrap;
			margin: 0 0 10px 0;
			border-bottom:2px solid #575757;
			border-top:1px solid rgba(#000,0.2);

			.produto-area ~ *{
				width: 33.333%;
				padding: 15px;
				border-top:1px solid rgba(#000,0.2);
			}

			.vlr-unit,
			.vlr-ttl{
				display: flex;
				align-items: center;
				justify-content: center;
				border-left: 1px solid rgba(#000,0.2);
			}

		}

		.produto-area{
			width: 100%;
			display: flex;

			.descricao{
				padding-left: 30px;
			}
		}

	}

	@include media-breakpoint-down(md){

		.carrinho-calcular{
			margin-bottom:30px;
		}

	}

	@include media-breakpoint-down(lg){

		.carrinho-footer{
			text-align:center;

			.nao-sei-cep{
				margin-bottom:20px;
			}

			.carrinho-entrega{
				margin-bottom:40px;

				.carrinho-flex{
					margin-bottom:10px;
				}

				.carrinho-total{


					.parte-1{
						margin-bottom:30px;
					}

				}

			}

			.carrinho-finalizar{
				margin-bottom:20px;

				.info{
					margin-bottom:20px;
					width:100%;
					display:block !important;
					text-align:center;
				}


			}

		}

	}

	@include media-breakpoint-down(md){

		.carrinho-footer{

			.info{
				
				.box-icon{
					margin-bottom:10px;
				}

			}

			.box-btn{
				margin-bottom:15px;
				width:100%;

				.btn{

					&:last-child{
						margin-bottom:0;
					}

				}

			}

			.carrinho-total{

				.parte-1 > .row{
					margin-bottom:20px;

					&:last-child{
						padding-top:15px;
						border-top:1px solid lighten($theme2,20%);
					}

				}

			}

		}

	}

	@include media-breakpoint-up(lg){

		.produto-area{
			flex-grow: 1;
			display: flex;
			justify-content:center;
		}

		.carrinho-footer{

			.carrinho-entrega{

				.carrinho-calcular{
					padding:20px 20px 12px 45px;
				}

				.carrinho-total{
					margin-left:30px;
					display:flex;
					flex-wrap:wrap;
					flex-direction:column;

					.parte-2{
						flex-grow:1;
					}

				}

			}

			.info{
				padding-left:6px;
				padding-right:10px;
				font-size:12px;

				.box-icon{
					margin-right:15px;
				}

			}

			.info-2{

				.form-group,
				.form-group .form-control{
					margin-bottom:0;
				}

				.box-btn{
					display:flex;
					flex-wrap:wrap;

					.btn:last-child{
						flex-grow:1;
					}

				}

			}

		}

		.descricao{
			padding-left:15px;
		}

		.qtde,
		.vlr-unit,
		.vlr-ttl,
		.arq,
		.arquivo-perso{
			min-width: 200px;
			max-width: 220px;
			padding:7px;
		}

		.carrinho-item{

			.qtde{

				.toggle-qtde{
					max-width:150px;
					margin-right:auto;
					margin-left:auto;
				}

			}

			.vlr-ttl,
			.vlr-unit{
				display: flex;
				align-items: center;
				justify-content:center;
			}

			.qtde,
			.vlr-unit,
			.vlr-ttl,
			.arquivo-perso{
				border-left:4px solid #E9E9E9;
			}

			.arquivo-perso{
				align-items:center;
				display:flex;
				flex-wrap:wrap;
				padding-left:18px;
				padding-right:18px;
				text-align:center;
				flex-direction:column;
				justify-content:flex-start;
				padding-top:24px;
			}

		}

		.carrinho-calcular{
			max-width:620px;
			margin-bottom:0;
		}

	}

	@include media-breakpoint-up(md){

		.carrinho-footer{

			.form-control{
				min-height:44px;
			}

			.carrinho-flex{
				display:flex;
				flex-wrap:wrap;
				width:100%;
				justify-content:space-between;
				padding-right:40px;

				.form-group{
					flex-grow:1;
					padding-right:20px;
				}

			}

			.carrinho-entrega{
				display:flex;
				flex-wrap:wrap;
				justify-content:space-between;
				margin-bottom:40px;

				.carrinho-calcular{
					width:100%;
					display:flex;
					flex-wrap:wrap;
				}

				.carrinho-total{
					flex-grow:1;
				}

			}

			.carrinho-finalizar{
				display:flex;
				flex-wrap:wrap;
				align-items:center;
				justify-content:space-between;

				.info-2{
					display:flex;
					flex-wrap:wrap;
					justify-content:space-between;
					flex-grow:1;

					.box-btn{

						& > *{

							&:first-child{
								min-width:130px;
							}

							&:last-child{
								min-width:190px;
							}

						}

						.btn + .btn{
							margin-left:26px;
						}

					}

					.form-group{
						display:flex;
						align-items:center;
						flex-grow:1;
						width:100%;
						max-width:290px;
						margin-right:auto;
						margin-left:auto;
					}

				}

			}

		}

	}

	@include media-breakpoint-up(xs){

		.carrinho-footer{

			.btn + .btn{
				margin-left:5px;
			}

		}

		.info{
			display:flex;
			flex-wrap:wrap;
			justify-content:space-between;
			align-items:center;

			p{
				margin-bottom:0;
			}

		}

	}

	@include media-breakpoint-down(sm){

		.carrinho-flex{
			display:flex;
			flex-wrap:wrap;

			& > *{
				width:100%;
			}

			.form-group{
				order:1;
			}

			.texto-calcular{
				order:2;
				margin-bottom:15px;
			}

			.box-btn{
				order:3;
			}

		}

	}

	@include media-breakpoint-down(lg){

		.qtde,
		.vlr-unit,
		.vlr-ttl,
		.arq,
		.arquivo-perso{
			max-width:100% !important;
			flex-grow:1;
		}

	}

}